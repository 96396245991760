@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/radii';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

$max-width: 1224 * tokens.$bpk-one-pixel-rem;

.Hero {
  position: relative;
  width: 100%;
  height: auto;
  background-color: tokens.$bpk-surface-contrast-day;
  background-repeat: no-repeat;
  background-position: 50% 60%;
  background-size: cover;

  [class*='flights-sc-wrapper'] {
    /* stylelint-disable-next-line declaration-no-important */
    max-width: none !important;
  }

  .InnerContentLarge {
    min-height: tokens.$bpk-one-pixel-rem * 550;
  }

  .Content {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding-top: tokens.bpk-spacing-lg();
    line-height: tokens.$bpk-line-height-base-tight;
  }

  .Title {
    color: tokens.$bpk-surface-default-day;
  }

  .SearchWrapper {
    margin: 0 auto;
    padding: tokens.bpk-spacing-lg() 0;
    background-color: tokens.$bpk-surface-contrast-day;

    @include radii.bpk-border-radius-xs;
  }

  .PackagesSearchWrapper {
    @include radii.bpk-border-radius-lg;
  }

  .SearchWrapperLarge {
    margin-top: tokens.bpk-spacing-lg();
    padding: tokens.bpk-spacing-lg();
  }

  .BubbleContent {
    margin: auto auto tokens.bpk-spacing-xl();
    padding: tokens.bpk-spacing-lg();
    border-radius: tokens.$bpk-border-radius-md;
    background-color: tokens.$bpk-surface-contrast-day;
  }

  .BubbleCentreContent {
    margin: auto;
  }

  .BubbleImage {
    display: none;
    width: 100%;
    background-image: var(--bg-image);
    background-position: center;
    background-size: cover;
    object-fit: cover;
  }

  .HotelsSearchWrapper {
    padding-top: tokens.bpk-spacing-lg();

    /* spacing to avoid CLS web vital issues */

    /* TODO CASTLE-1208 remove this when SSR HotelSearchControls is done */

    // covers the horizontal search control
    @media screen and (max-width: (512 * tokens.$bpk-one-pixel-rem)) {
      min-height: 135 * tokens.$bpk-one-pixel-rem;
    }

    // at this point we show the larger search control wrapped on several lines so more space is needed
    @media screen and (min-width: (513 * tokens.$bpk-one-pixel-rem)) and (max-width: (661 * tokens.$bpk-one-pixel-rem)) {
      min-height: 300 * tokens.$bpk-one-pixel-rem;
    }

    @media screen and (min-width: (662 * tokens.$bpk-one-pixel-rem)) and (max-width: (750 * tokens.$bpk-one-pixel-rem)) {
      min-height: 270 * tokens.$bpk-one-pixel-rem;
    }

    @media screen and (min-width: (751 * tokens.$bpk-one-pixel-rem)) and (max-width: (1024 * tokens.$bpk-one-pixel-rem)) {
      min-height: 260 * tokens.$bpk-one-pixel-rem;
    }

    @media screen and (min-width: (1025 * tokens.$bpk-one-pixel-rem)) and (max-width: (1224 * tokens.$bpk-one-pixel-rem)) {
      min-height: 180 * tokens.$bpk-one-pixel-rem;
    }

    @media screen and (min-width: (1225 * tokens.$bpk-one-pixel-rem)) {
      min-height: 195 * tokens.$bpk-one-pixel-rem;
    }

    /* @include for mobile breakpoint intentionally "out of order" and after @media selectors so it applies after */
    /* stylelint-disable order/order */
    @include breakpoints.bpk-breakpoint-mobile {
      padding-top: unset;
    }
  }

  .HotelsSearchControlsGCWrapper {
    padding-top: tokens.bpk-spacing-lg();

    // covers the horizontal search control
    @media screen and (max-width: (512 * tokens.$bpk-one-pixel-rem)) {
      min-height: 135 * tokens.$bpk-one-pixel-rem;
    }

    // at this point we show the larger search control wrapped on several lines so more space is needed
    @media screen and (min-width: (513 * tokens.$bpk-one-pixel-rem)) and (max-width: (661 * tokens.$bpk-one-pixel-rem)) {
      min-height: 300 * tokens.$bpk-one-pixel-rem;
    }

    @media screen and (min-width: (662 * tokens.$bpk-one-pixel-rem)) and (max-width: (750 * tokens.$bpk-one-pixel-rem)) {
      min-height: 270 * tokens.$bpk-one-pixel-rem;
    }

    @media screen and (min-width: (751 * tokens.$bpk-one-pixel-rem)) and (max-width: (1024 * tokens.$bpk-one-pixel-rem)) {
      min-height: 260 * tokens.$bpk-one-pixel-rem;
    }

    @media screen and (min-width: (1025 * tokens.$bpk-one-pixel-rem)) and (max-width: (1224 * tokens.$bpk-one-pixel-rem)) {
      min-height: 180 * tokens.$bpk-one-pixel-rem;
      padding-right: tokens.bpk-spacing-base();
      padding-left: tokens.bpk-spacing-base();
    }

    @media screen and (min-width: (1225 * tokens.$bpk-one-pixel-rem)) {
      min-height: 195 * tokens.$bpk-one-pixel-rem;
    }

    @include breakpoints.bpk-breakpoint-tablet {
      padding-top: unset;
      padding-right: tokens.bpk-spacing-base();
      padding-bottom: tokens.bpk-spacing-base();
      padding-left: tokens.bpk-spacing-base();
    }

    @include breakpoints.bpk-breakpoint-small-tablet {
      padding-top: unset;
      padding-right: tokens.bpk-spacing-base();
      padding-bottom: tokens.bpk-spacing-base();
      padding-left: tokens.bpk-spacing-base();
    }

    @include breakpoints.bpk-breakpoint-mobile {
      padding-top: unset;
      padding-right: tokens.bpk-spacing-base();
      padding-bottom: tokens.bpk-spacing-base();
      padding-left: tokens.bpk-spacing-base();
    }
  }
}

@media screen and (min-width: $max-width) {
  .BubbleContent {
    > .SearchWrapper {
      padding: 0;
    }
  }
}

@include breakpoints.bpk-breakpoint-mobile {
  .Hero {
    height: auto;
    min-height: 0;
    background-color: tokens.$bpk-surface-contrast-day;
    background-image: none !important; /* stylelint-disable-line declaration-no-important */

    .InnerContentLarge {
      min-height: unset;
    }

    .Title {
      padding: tokens.bpk-spacing-lg() tokens.bpk-spacing-base()
        tokens.bpk-spacing-base() tokens.bpk-spacing-base();

      @include typography.bpk-heading-2;
    }

    .Content {
      margin: 0 auto;
      padding: 0;
    }

    .SearchWrapper {
      margin: 0;
      padding: 0 tokens.bpk-spacing-base() tokens.bpk-spacing-lg()
        tokens.bpk-spacing-base();
      background-color: tokens.$bpk-surface-contrast-day;
    }

    .SearchWrapperLarge {
      margin: 0;
      padding: 0 tokens.bpk-spacing-base() tokens.bpk-spacing-lg()
        tokens.bpk-spacing-base();
      background-color: tokens.$bpk-surface-contrast-day;
    }

    .Flare {
      display: none;
    }
  }
}

@media screen and (max-width: $max-width) and (min-width: tokens.$bpk-breakpoint-mobile) {
  .Hero {
    height: auto;
    min-height: 0;
    background-color: tokens.$bpk-surface-contrast-day;
    background-image: none !important; /* stylelint-disable-line declaration-no-important */

    .InnerContentLarge {
      min-height: unset;
    }

    .Content {
      margin: 0 auto;
      padding: 0;
    }

    .SearchWrapper {
      margin: 0;
      padding: 0 tokens.$bpk-breakpoint-small-tablet-margin
        tokens.bpk-spacing-lg() tokens.$bpk-breakpoint-small-tablet-margin;
      background-color: tokens.$bpk-surface-contrast-day;
    }

    .SearchWrapperLarge {
      margin: 0;
      padding: 0 tokens.$bpk-breakpoint-small-tablet-margin
        tokens.bpk-spacing-lg() tokens.$bpk-breakpoint-small-tablet-margin;
      background-color: tokens.$bpk-surface-contrast-day;
    }

    .Title {
      padding: tokens.bpk-spacing-lg()
        tokens.$bpk-breakpoint-small-tablet-margin;

      @include typography.bpk-heading-2;
    }

    .HotelsSearchWrapper {
      padding-top: 0;
    }

    .Flare {
      display: none;
    }
  }
}
